<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addProductParam']">新增产品参数</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editProductParam']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteProductParam']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="产品参数名称/ID" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item  label="关联产品类型">
              <a-select v-model="filterForm.productId" placeholder="请选择关联产品类型">
                <a-select-option :value="item.id" v-for="(item) in productList" :key="item.id">{{ item.productName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}产品参数` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="产品参数名称" prop="productParametersName">
              <a-input placeholder="请输入产品参数名称" v-model="form.productParametersName" />
            </a-form-model-item>
            <a-form-model-item label="产品参数ID" prop="productParametersCode">
              <a-input placeholder="请输入产品参数ID" v-model="form.productParametersCode" />
            </a-form-model-item>
            <a-form-model-item  label="数据单位" prop="dataUnit">
              <a-select v-model="form.dataUnit" placeholder="请选择数据单位">
                <a-select-option :value="item" v-for="(item, index) in unitList" :key="index">{{ item }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getProductParamList,
  createProductParam,
  deleteProductParam,
  getProductParamListNew
} from '../../api/product/productParam'
import { getProductList } from '../../api/product/product'
export default {
  name: 'productParam',
  data () {
    return {
      columns: [
        {
          title: '产品参数名称',
          dataIndex: 'productParametersName'
        },
        {
          title: '产品参数ID',
          dataIndex: 'productParametersCode'
        },
        {
          title: '数据单位',
          dataIndex: 'dataUnit'
        },
        {
          title: '关联产品（类型）',
          dataIndex: 'productName'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        productParametersName: [
          { required: true, message: '请输入产品参数名称', trigger: 'blur' }
        ],
        productParametersCode: [
          { required: true, message: '请输入产品参数ID', trigger: 'blur' }
        ]
      },
      productList: [],
      unitList: ['V', 'A', '℃', 'PB',  'TB', 'GB', 'MB']
    }
  },
  mounted () {
    this.getList()
    this.getProduct()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getProductParamListNew(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteProductParam({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createProductParam(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList(this.filterForm).then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    getProduct () {
      getProductList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.productList = res.data.list
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
